@use 'mixins';

$-z-index-dropdown: 3;

%-dropdown-transition {
    transition:
        opacity 0.15s ease,
        transform 0.15s ease,
        visibility 0.15s;
    transition-delay: 0.15s;
}

%-dropdown-container-style {
    background-color: rgb(var(--background));
    border: 1px solid rgb(var(--sectionBorder));
    border-radius: var(--standardRadius);
    box-shadow: 0 0 var(--tinyPadding) rgba(var(--invertedBackground), 0.2);
}

.ActionsDropdown {
    position: relative;
    max-width: min-content;
    float: right;
    line-height: 1.5;
    text-transform: capitalize;
    color: rgb(var(--textColor));
    cursor: pointer;

    :global(.Icon.dots-icon) {
        width: 24px;
        height: 24px;
    }

    li {
        display: flex;
    }

    .dropdown {
        position: absolute;
        top: 1.75em;
        right: 0;
        max-height: 20vh;
        min-width: 12em;
        opacity: 0;
        visibility: hidden;
        overflow: hidden scroll;
        z-index: #{$-z-index-dropdown}; /* Ensure the dropdown appears above the overlay and the button of the overlay */

        @extend %-dropdown-container-style;
        @extend %-dropdown-transition;

        &.expanded {
            height: auto;
            opacity: 1;
            visibility: visible;

            ul {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding-right: var(--standardPadding);
            }
        }

        .list-item {
            flex: 1;
            padding: var(--standardPadding) 0 var(--standardPadding) var(--standardPadding);
            line-height: 1.5em;
            border-bottom: 1px solid rgb(var(--sectionBorder));
            font-family: var(--textFont);
            font-weight: 600; // semi-bold
            color: rgb(var(--lighterTextColor));
            transition: color 0.15s ease-in-out;

            p {
                margin: 0;
                margin-left: var(--smallPadding);
                white-space: nowrap;
            }

            &:hover {
                color: rgb(var(--linkColor));
            }

            &:last-of-type {
                border-bottom: 0;
            }
        }
    }
}

@use 'mixins';

.LoginButton {
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    text-transform: uppercase;
    color: rgb(var(--invertedTextColor), 0.8);

    .label-login {
        display: none;
    }

    [data-theme='vi'] & {
        text-transform: capitalize;

        .label-login {
            display: inline-block;
            margin-right: var(--smallPadding);
            line-height: 1.5em;
            color: rgb(var(--lighterTextColor));
        }
    }

    .subscribe-button {
        padding: var(--tinyPadding) var(--mediumPadding);
        background-color: rgb(var(--invertedBackground));
        line-height: 1.5em;
        font-size: 1.125em;
        font-family: var(--pro-article-heading-font-family);
        color: rgb(var(--invertedTextColor));
        text-decoration: none;
        font-weight: 500;
        border-radius: var(--largePadding);
    }

    @include mixins.responsive(l, above) {
        .label-login {
            display: inline-block;
            margin-left: calc(-1 * var(--smallPadding));
            font-weight: 600;
        }

        .subscribe-button {
            padding: var(--smallPadding) calc(var(--standardPadding) + var(--mediumPadding));
            font-size: 1.25em;
        }
    }
}

@use 'mixins';

.About {
    padding: var(--largePadding) 0 0 0;

    .content {
        text-align: center;
    }

    button {
        @extend %caption-medium;

        border: none;
        color: rgb(var(--lighterTextColor));
        font-size: 1em;
        text-transform: uppercase;

        &::first-letter {
            text-transform: capitalize;
        }

        &:hover {
            background: none;
        }
    }

    .is-shown {
        width: 100%;
        padding-top: var(--standardPadding);
        color: rgb(var(--secondaryTextColor));
        background-color: rgb(var(--sectionBack));

        &:hover {
            background: rgb(var(--sectionBack)) !important;
        }
    }

    .default {
        display: none;
    }

    .load-more {
        background-color: rgb(var(--sectionBack));

        p {
            @extend %paragraph-medium;

            padding: var(--standardPadding);
        }
    }
}

.ExpandableSearchBar {
    display: flex;
    flex-direction: row;
    width: 5.5em;
    padding: var(--tinyPadding);
    transition: all 0.1s ease-in-out;
    cursor: text;

    .search-input {
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;
        padding-left: var(--smallPadding);
        background-color: transparent;
        border: none;
        font-size: 0.875em;
        font-family: var(--textFont);
        font-weight: 600;
        color: inherit;
        text-align: left;

        &::placeholder {
            opacity: 0.6;
            color: rgb(var(--background));
        }

        &:focus {
            outline: none;
        }
    }

    &.expanded {
        width: 100%;
    }

    :global(.Icon) {
        opacity: 0.6;
        height: 1.5em;
        margin-top: var(--tinyPadding);
        font-size: 1.5em;
        cursor: pointer;
        color: rgb(var(--invertedTextColor));
    }

    .clear-search {
        :global(.Icon) {
            opacity: 0.6;
        }
    }

    &.default {
        background: transparent;

        &.is-inverted {
            border: 0;

            .search-input::placeholder {
                color: rgb(var(--lighterTextColor));
            }

            :global(.Icon) {
                color: rgb(var(--lighterTextColor));
            }
        }
    }

    &.small {
        position: relative;
        opacity: 1;
        width: 4em;
        padding: var(--smallPadding);
        background-color: rgb(var(--background));

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: var(--standardPadding);
            height: 100%;
            background: linear-gradient(to right, transparent, rgba(var(--sectionBorder), 1));
        }

        .search-input {
            color: rgb(var(--lighterTextColor));

            &::placeholder {
                opacity: 1;
                color: rgb(var(--lighterTextColor));
            }
        }

        .search-icon {
            position: absolute;
            left: 50%;
            transform: translateX(-25%);
            margin: auto;
            pointer-events: none;
        }

        :global(.Icon) {
            font-size: 1.25em;
            color: rgb(var(--lighterTextColor));
        }

        &.expanded {
            width: 100%;

            .search-input {
                color: rgb(var(--textColor));
            }

            .search-icon {
                display: none;
            }
        }
    }
}

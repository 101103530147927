.ErrorIntroBlock {
    display: block;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    background: rgb(var(--invertedBackground));

    [data-theme='gp'] & {
        border-top: 3px solid rgb(var(--mainColor));
    }
}

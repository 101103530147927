@use 'mixins';

.SubNavigation {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 2.75em;
    font-weight: 600;

    [data-theme='gp'] & {
        text-transform: uppercase;
    }

    nav {
        display: flex;
        flex: 1 1 auto;
        flex-direction: row;
        position: relative;
        overflow-x: auto;
        white-space: nowrap;
        scrollbar-width: none;

        &.collapsed {
            z-index: 0;
            opacity: 0;
            overflow: hidden;
            width: 0;
            height: 100%;
            transition: width 0.25s ease-in-out;
        }

        ul {
            display: flex;
            flex-direction: row;
            overflow-x: auto;
            white-space: nowrap;

            /* Hide scroll bars */
            -ms-overflow-style: none; /* Internet Explorer 10+ */
            scrollbar-width: none; /* Firefox */

            &::-webkit-scrollbar {
                display: none; /* Safari and Chrome */
            }
        }

        ul li {
            display: inline-flex;
            align-items: center;
            position: relative;
            padding: 0 var(--tinyPadding);
            margin-right: var(--largePadding);
            font-size: 0.85em;
            cursor: pointer;
            white-space: nowrap;
            letter-spacing: 1px;
            color: rgb(var(--lighterTextColor));

            a {
                flex: 1;
                align-items: center;
                line-height: 2.75em;
                color: inherit;
                text-decoration: none;
                text-transform: uppercase;
            }

            [data-theme='vi'] & {
                margin-right: var(--standardPadding);
                letter-spacing: normal;

                a {
                    text-transform: capitalize;
                }
            }
        }

        :global(.Icon) {
            flex: 1;
            font-size: 1.5em;
            height: var(--largePadding);
            margin-top: var(--smallPadding);
            margin-right: var(--smallPadding);
            padding-left: var(--smallPadding);
            color: rgb(var(--lighterTextColor));

            &:hover {
                color: rgb(var(--mainColor));
            }
        }
    }

    :global(.Logo.pro-logo) {
        display: flex;
        align-items: center;
        height: 100%;
        width: auto;

        svg {
            height: 1rem;
            width: auto;
        }
    }

    a[href*='/pro'] {
        display: inline-block;
        height: 100%;
    }

    .home-icon-active,
    .is-active {
        height: calc(100% - 2px);
        border-bottom: 2px solid rgb(var(--mainColor));
        color: rgb(var(--mainColor));

        &:hover {
            color: rgb(var(--lighterTextColor));
        }

        :global(.Icon) {
            color: rgb(var(--mainColor));

            &:hover {
                color: rgb(var(--lighterTextColor));
            }
        }
    }

    @include mixins.responsive(l, above) {
        overflow: hidden;
        vertical-align: baseline;
        white-space: nowrap;
        text-transform: uppercase;
        margin: 0 auto;

        li {
            display: inline-block;
            position: relative;
            padding: var(--standardPadding);
            font-size: 0.85em;
            cursor: pointer;
            letter-spacing: 1px;
            color: rgb(var(--lighterTextColor));

            &:hover {
                color: rgb(var(--mainColor));
            }

            a {
                text-decoration: none;
                color: inherit;
            }
        }

        [data-theme='vi'] & nav ul li {
            margin-right: var(--mediumPadding);
            font-size: 1em;
        }

        .pro :global(.Icon) {
            font-size: 3.5em;
        }

        nav :global(.Icon) {
            height: auto;
            margin-top: var(--smallPadding);
        }
    }
}
